import { useContext, useEffect, useState } from "react";
// import constFile from '../Middleware/ConstFile';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import MustReadSideBar from "../../Component/SideNews/MustReadSideBar";
import Loader from "../../Component/UI/Loader";
import CurrentIssue from "../../Component/Third-PartyAds/CurrentIssue";
import ReactPaginate from "react-paginate";
import { Constants } from "../../Middleware/Constant";
import { DataContext } from "../../Component/Context/SidebarContext";
import SideAd from "../../Component/Third-PartyAds/Advertise";
import TrendingPosts from "../../Component/SideNews/TrendingPost";
import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
// import { Helmet } from "react-helmet-async";
import editIcon from "../../assets/img/pencil_2606509 (2).png";

export default function CategoryPage() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { sectionType } = useParams();
  const numberOfPost = 24;
  const {
    data: adsData,
    homeNews: trendingNews,
    user,
  } = useContext(DataContext);
  // const { data: tags } = useFetchMetaTags(sectionType);

  const ads = adsData?.data;
  const trendingData = trendingNews?.data?.trending_news;

  // const controller = new AbortController();
  // const timeoutId = setTimeout(() => controller.abort(), 10000);

  const fetchNews = async (page) => {
    setLoading(true);
    setError(null);

    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("cate_slug", sectionType);
    urlEncodedData.append("number_of_posts", numberOfPost);
    urlEncodedData.append("page", page);

    try {
      if (!sectionType) {
        throw new Error(`The Page You are Looking for is not Found`);
      }
      const response = await fetch(Constants.backendUrl + "category-news/", {
        method: "POST",
        headers: {
          Authorization: `Basic ${Constants.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlEncodedData.toString(),
        // signal: controller.signal,
      });
      // clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setNews(result);
      setPageCount(Math.ceil(result?.data?.total_posts / numberOfPost));
    } catch (error) {
      if (error.name === "AbortError") {
        setError("Request timed out. Please try again later.");
      } else if (error.message === "Failed to fetch") {
        setError("Failed to fetch. Please try again later.");
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const page = queryParams.get("page");
  //   const pageNumber = page ? parseInt(page) : 1;
  //   setCurrentPage(pageNumber - 1);
  //   fetchNews(page || currentPage + 1);
  // }, [sectionType, currentPage]);

  // const handlePageClick = (data) => {
  //   setCurrentPage(data.selected);
  //   navigate(`?page=${data.selected + 1}`);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("page");
    const pageNumber = page ? parseInt(page) : 1;

    fetchNews(pageNumber);
    setCurrentPage(pageNumber - 1);
  }, [sectionType]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    navigate(`?page=${selectedPage + 1}`);
    fetchNews(selectedPage + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // useEffect(() => {
  //   // Find the generated ul and remove the role attribute
  //   const ulElement = document.querySelector(".pagination");
  //   console.log(ulElement);
  //   if (ulElement && ulElement.getAttribute("role")) {
  //     ulElement.removeAttribute("role");
  //   }
  // }, [currentPage]);

  return (
    <>
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      {
        <section className="sm:pb-5 news sm:mb-20 pb-2 mb-10 bg-blur">
          <div className="container mx-auto sm:px-7 px-3">
            {loading === true ? (
              <>
                <Loader /> <Skeleton height={200} count={5}></Skeleton>
              </>
            ) : error ? (
              <span className="container mx-auto px-7">{error}</span>
            ) : (
              <>
                {news?.data?.posts?.length > 0 && (
                  <>
                    <div className="flex justify-between items-center gap-10 sm:gap-5 md:px-4 px-2 border-b border-gray-600 pb-2 mb-5">
                      <div>
                        <h1 className="text-[#231E1E] lg:text-2xl md:text-lg text-sm text-nowrap font-bold uppercase">
                          {news?.data?.category_name}
                        </h1>
                      </div>
                      <div className="news-item sm:pb-2 pb-1 text-nowrap overflow-x-auto overflow-y-hidden">
                        <ul className="flex text-[#231E1E]   list-none my-1">
                          {news?.data?.child_category?.length > 0 &&
                            news?.data?.child_category?.map((item, i) => (
                              <li key={item.cate_id} className="ml-2">
                                <NavLink
                                  to={`/category/${item.cate_slug}`}
                                  className={({ isActive }) =>
                                    `lg:px-4 sm:px-3 px-1 py-1 md:text-[12px] text-[10px] rounded-md border uppercase font-bold ${
                                      isActive
                                        ? "border-red text-red" // Active link styling
                                        : "border-white hover:border-red" // Inactive link styling
                                    }`
                                  }
                                >
                                  {item.cate_title}
                                </NavLink>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>

                    <div className="grid grid-cols-12 gap-5">
                      <div className="lg:col-span-9 md:col-span-8 col-span-12">
                        <div className="grid grid-cols-12 gap-5 mb-10">
                          {news?.data?.posts?.map((post, i) => (
                            <div
                              key={i}
                              className="lg:col-span-4 sm:col-span-6 col-span-12"
                            >
                              <div className="rounded-[10px] bg-[#EEEEEE] h-full">
                                <div
                                  className={`${
                                    user ? "group/item edit-hover relative" : ""
                                  }`}
                                >
                                  <Link
                                    to={`/${post.post_slug}`}
                                    aria-label={`${post.post_slug}`}
                                    // className="edit-img-hover block relative "
                                  >
                                    {" "}
                                    <img
                                      src={post.post_image}
                                      alt=""
                                      className="w-full rounded-[10px] lg:h-[170px]  sm:max-h-48 sm:min-h-48 max-h-56 min-h-56 object-cover"
                                      // style={{ width: "100%" }}
                                    />
                                  </Link>
                                  {/* {user && (
                                    <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-3 py-2 font-bold rounded-lg shadow-md shadow-black">
                                      <img
                                        src={editIcon}
                                        className="inline-block h-3 w-3  mb-1"
                                        alt="edit"
                                      />
                                    </div>
                                  )} */}
                                </div>
                                <div className="px-[23px] pt-[18px] pb-[23px]">
                                  <Link to={`/category/${post.cat.cate_slug}`}>
                                    <span className="text-[10px] text-[#5e5e5e] font-openSans  font-semibold uppercase">
                                      {post.cat.cate_title}
                                    </span>
                                  </Link>
                                  <Link
                                    to={`/${post.post_slug}`}
                                    aria-label={`${post.post_slug}`}
                                  >
                                    <h2 className="text-sm mt-[15px] mb-[10px] font-black wow fadeInDown hover:text-red">
                                      {post.post_title}
                                    </h2>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        {pageCount > 1 && (
                          <div className="flex sm:justify-between justify-center items-center">
                            <ReactPaginate
                              previousLabel={"previous"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />

                            <div className="text-[#5e5e5e] hidden sm:block text-[10px]">
                              {`page ${currentPage + 1} of ${pageCount}`}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="lg:col-span-3 md:col-span-4 col-span-12">
                        <div className="mb-10">
                          <SideAd htmlData={ads?.mpu_1_ads} />
                          <SideAd htmlData={ads?.mpu_2_ads} />
                          <SideAd htmlData={ads?.mpu_3_ads} />
                          <SideAd htmlData={ads?.mpu_4_ads} />

                          {/* {news?.data?.posts?.length > 3 && ( */}
                          <>
                            <SideAd htmlData={ads?.mpu_5_ads} />
                            <SideAd htmlData={ads?.mpu_6_ads} />
                          </>
                          {/* // )} */}
                        </div>
                        <div className="sm:mb-[101px] mb-10">
                          <TrendingPosts
                            trendingPost={trendingData}
                            user={user}
                          />
                        </div>
                        {/* {news?.data?.posts?.length > 3 && ( */}
                        <div className="col-span-3 bg-[#231E1E] p-5 rounded-[10px]">
                          <CurrentIssue />
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </>
                )}
                {news?.data?.posts?.length === 0 && (
                  <div className="ml-10 text-red">
                    Data Not Found
                    <span className="px-2">
                      Go back to
                      <Link
                        to={"/"}
                        aria-label="Home"
                        className="mx-2 bg-black py-1 px-3 rounded-md text-white"
                      >
                        Home page
                      </Link>
                      ?
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </section>
      }
    </>
  );
}
